import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";
import veiwIcon from "../../../Images/viewicon.svg"
import arrowRight from "../../../Images/arrow.svg"

const PortfolioData = () => {
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activePortfolio, setActivePortfolio] = useState(null);

  // Fetch portfolio items
  const handleAddPortfolio = async (type) => {
    try {
      const url = `https://perfectwebservices.com/Apis/showportfolio.php?timestamp=${Date.now()}`;
      const response = await axios.get(url);
      if (response.data && response.data.portfolioItems) {
        setPortfolioItems(response.data.portfolioItems);
      } else {
        console.error("Error: Portfolio items not found in API response");
      }
    } catch (error) {
      console.error("Error fetching data:", error.response);
    }
  };

  useEffect(() => {
    handleAddPortfolio(selectedTab);
  }, [selectedTab]);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Open modal with active portfolio item
  const openModal = (item) => {
    setActivePortfolio(item);
    setModalIsOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setModalIsOpen(false);
    setActivePortfolio(null);
  };

  return (
    <section className="our-portfolio padd" id="portfolio">
      <div className="container">
        <div className="row">
        <div className="section-heading">
            <div className="small-subheading">
                <span>OUR PORTFOLIO</span>
                <hr></hr>
            </div>
            <h2 className="text-center">Our Recent Launched Projects Available</h2>
        </div>
        <div className="col-md-12">
            <div className="portfolio-carousel">
              <Slider {...settings}>
                {portfolioItems
                  .filter((item) => selectedTab === "all" || item.category === selectedTab)
                  .map((item, index) => (
                    <div key={index} className="portfolio-item" onClick={() => openModal(item)}>
                      <div className="inside">
                        <div className="details">
                          <div className="text">
                            <h3>{item.title}</h3>
                            <img src={veiwIcon} alt="veiwIcon" />
                          </div>
                        </div>
                        <img
                          src={`https://perfectwebservices.com/admin${item.image}`}
                          alt={item.title}
                        />
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="row ">
            <div className="col-12 text-center">
                <div className="button-center">
                    <a href="#" className="button">Explore More <img src={arrowRight} alt="arrow" /></a>
                </div>
            </div>
        </div>
      </div>

      {/* Modal Section */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Portfolio Details"
        ariaHideApp={false}
        className="portfolio-modal"
        overlayClassName="portfolio-modal-overlay"
      >
        {activePortfolio && (
          <div className="modal-content">
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
           <div className="modal-body">
                <div className="portfolioimage">
                    <img
                    src={`https://perfectwebservices.com/admin${activePortfolio.image}`} alt={activePortfolio.title} />
                </div>
                
                <div className="porfolio-content">
                    <h2>{activePortfolio.title}</h2>
                    <p>{activePortfolio.description}</p>
                    <a href={activePortfolio.url} target="_blank" rel="noopener noreferrer" className="portfolio-link button"> View More <img src={arrowRight} alt="arrow" /></a>
                </div>
            </div>
          </div>
        )}
      </Modal>
    </section>
  );
};

export default PortfolioData;
