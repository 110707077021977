import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialimageOne from "../../../Images/testimonial-image-first.webp";
import TestimonialimageTwo from "../../../Images/testimonial-image-second.webp";
import aboutBg from "../../../Images/aboutbg.webp";

// Array of testimonial data
const testimonialData = [
  {
    author: "Sarah Johnson",
    location: "Paris, France",
    quote: "I had an amazing experience with this IT company. They were professional, responsive, and delivered our project on time. The team went above and beyond to ensure our needs were met. Highly recommended!",
    image: TestimonialimageOne,
    ratings: 5,
  },
  {
    author: "David Lee",
    location: "New York City, USA",
    quote: "Coming from a city known for its cuisine, I was impressed by the culinary artistry at your restaurant. The flavors were exquisite and left a lasting impression.",
    image: TestimonialimageOne,
    ratings: 4,
  },
  {
    author: "Theo Sorel",
    location: "Barcelona, Spain",
    quote: "As someone with a love for food, your restaurant was a delightful discovery. The quality of ingredients and the cozy atmosphere made it a culinary experience to cherish.",
    image: TestimonialimageOne,
    ratings: 5,
  },
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      className="testimonial-section padd"
      style={{
        backgroundImage: `url(${aboutBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="testimonial-image-block">
              <div className="testimonial-first-image">
                <img src={TestimonialimageOne} alt="TestimonialimageOne" />
              </div>
              <div className="testimonial-second-image">
                <img src={TestimonialimageTwo} alt="TestimonialimageTwo" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="testimonial-content-block">
              <div className="section-heading">
                <div className="small-subheading">
                  <span>What Clients Say</span>
                  <hr />
                </div>
                <h2 className="">Hear What Our Global Clients Say</h2>
              </div>
              <div className="testimonial-slider">
                <Slider {...settings}>
                  {testimonialData.map((testimonial, index) => (
                    <div key={index} className="testimonial-item">
                      <div className="author-content">
                        <div className="author-rating">
                          {Array.from({ length: testimonial.ratings }).map((_, i) => (
                            <i key={i} className="fa-solid fa-star"></i>
                          ))}
                        </div>
                        <div className="author-disc">
                          <p>{testimonial.quote}</p>
                        </div>
                        <div className="author-info">
                          <div className="author-img">
                            <img
                              src={testimonial.image}
                              className="author h-100"
                              alt="author"
                            />
                          </div>
                          <div className="author-name-location">
                            <h5>{testimonial.author}</h5>
                            <p>{testimonial.location}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;