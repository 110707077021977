import React from 'react'
import slideImageone from "../../../Images/slide-image-one.webp"
import arrowRight from "../../../Images/arrow.svg"

const Getyourquote = () => {
    return (
        
        <section className="quoteBanner-block padd" style={{ backgroundImage: `url(${slideImageone})`,  backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="container">
                <div className="section-heading">
                    <h2 className="text-center">IT Solutions & Services at your Fingertips</h2>
                    <a href="#contactus" className="button">Get Your Quote <img src={arrowRight} alt="arrow" /></a>
                </div>
            </div>
        </section>
    
    )
}

export default Getyourquote 