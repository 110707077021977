import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import UpperFooter from '../Footer/UpperFooter'
import CounterSection from "./HomeComponents/CounterSection";
import aboutImage from "../../Images/about-image.webp"
import arrowRight from "../../Images/arrow.svg"
import AboutLeftimage from "../../Images/about-left-image.webp"
import CEO from "../../Images/ceo.webp"
import DIcon from "../../Images/D-Icon.svg"
import UIcon from "../../Images/U-icon.svg"
import WIcon from "../../Images/W-icon.svg"
import CIcon from "../../Images/C-icon.svg"
import RIcon from "../../Images/R-icon.svg"
import MIcon from "../../Images/M-icon.svg"
import visionIcon from "../../Images/visionicon.svg"
import missionIcon from "../../Images/missionicon.svg"

const AboutUs = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.sectionId) {
      const section = document.getElementById(location.state.sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const leadingBlocksData = [
    {
      icon: DIcon,
      title: "Digital Marketing",
      description:
        "Combining strategizing skills with design and technology, we create the best-in-class web designs that are effective and impressive for the client's industry that ultimately influence user satisfaction and aid in business success.",
    },
    {
      icon: UIcon,
      title: "UI/UX Designing",
      description:
        "We create the best-in-class web designs that are effective and impressive for the client's industry that ultimately influence user satisfaction and aid in business success.",
    },
    {
      icon: WIcon,
      title: "Web Development", 
      description:
        "Our web development services ensure user-friendly and scalable websites that drive growth and engagement for businesses.",
    },
    {
      icon: CIcon,
      title: "Creative Designer",
      description:
        "Our creative designers bring innovative ideas to life, ensuring visually stunning designs tailored to your business needs.",
    },
    {
      icon: RIcon,
      title: "React Frontend",
      description:
        "Our React frontend development services provide dynamic and responsive user interfaces for a seamless user experience.",
    },
    {
      icon: MIcon,
      title: "MERN/MEAN",
      description:
        "We specialize in full-stack development using MERN/MEAN stacks to deliver high-performing web applications.",
    },
  ];

  return ( 
    <main className="about-main">
      <Header />
      <section
        className="common_banner padd"
        style={{
          backgroundImage: `url(${aboutImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h1 className="text-center text-white position-relative">
            We Discover, We Design, We Execute
          </h1>
        </div>
      </section>
      <section className="about-main-blocks padd">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="about-block-left">
                <div className="about-block-image">
                  <span>
                    <img src={AboutLeftimage} alt="AboutOne" />
                  </span>
                  <p>
                    <a href="#contactus" className="button">
                      Let’s Discuss your project{" "}
                      <img src={arrowRight} alt="arrow" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="about-block-right">
                <div className="section-heading">
                  <div className="small-subheading">
                    <span>Who We Are</span>
                    <hr />
                  </div>
                  <h2 className="">Take a closer look at our excellence</h2>
                  <p>Perfect Web Solutions is a top web development company  offering custom solutions, including web and app development, CMS (WordPress), e-commerce, PHP, and UI/UX design. With 10+ years of experience, we help businesses create scalable, user-friendly websites and boost their online presence through digital marketing strategies like SEO, content, and social media.
                  </p>
                </div>
                <div className="about-mission-vision">
                  <div className="mission-vision-block left">
                    <div className="mission-vision-heading">
                      <img src={visionIcon} alt="visionIcon" />
                      <h5>Our Vision</h5>
                    </div>
                    <div className="mission-vision-content">
                      <p>To lead in innovative IT solutions, empowering businesses to grow, adapt, and succeed in the evolving digital world through cutting-edge technology and lasting partnerships.</p>
                    </div>
                  </div>
                  <div className="mission-vision-block right">
                    <div className="mission-vision-heading">
                      <img src={missionIcon} alt="missionIcon" />
                      <h5>Our Vision</h5>
                    </div>
                    <div className="mission-vision-content">
                      <p>To lead in innovative IT solutions, empowering businesses to grow, adapt, and succeed in the evolving digital world through cutting-edge technology and lasting partnerships.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <CounterSection />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="leading-part">
                  <div className="leading-heading">
                    <h2>Leading the Way in Web Design with Perfect Web Solutions</h2>
                    <p>Perfect Web Solutions is a renowned web design company with over a decade of experience, delivering high-quality web design services to clients worldwide. Our excellence in design, expert team of web designers, and flawless project delivery set us apart in the IT sector, with more than 1,000 satisfied clients. The growth of our clients is at the heart of our business.</p>
                  </div>
                  <div className="leading-discription">
                    <p>Our team stays up-to-date with the latest industry trends, ensuring we guide your business to success. We understand the critical elements of a successful website design, including the right size, font, color, and layout. Our skilled professionals use this knowledge to create visually stunning designs that drive lead generation and improve website conversion rates. This commitment to excellence makes us a preferred web design partner for entrepreneurs worldwide. </p>
                  </div>
                  <div className="leading-blocks">
                    {leadingBlocksData.map((block, index) => (
                      <div className="leading-block" key={index}>
                        <div className="leading-subheading">
                          <img src={block.icon} alt={`${block.title} Icon`} />
                          <h5>{block.title}</h5>
                        </div>
                        <div className="leading-block-discription">
                          <p>{block.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="CEO-Section padd">
        <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="CEO-Bg">
                  <div className="CEO-left">
                    <div className="ceo-inner">
                      <div className="ceo-discription">
                        <p><span>"</span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                      </div>
                      <div className="ceo-bio">
                        <a href="mailto:CEO @perfectwebservices">
                          <p>Paras Latawa</p>
                          <span>CEO @perfectwebservices</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="CEO-right">
                    <img src={CEO} alt="CEO" />
                    <div className="social-icons">
                      <ul>
                        <li>
                          <a href="https://www.facebook.com/perfectwebservices"><i className="fa-brands fa-facebook"></i></a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/perfect-web-services/"><i className="fa-brands fa-linkedin"></i></a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/perfect_web_services/"><i className="fab fa-instagram"></i></a>
                        </li>
                        <li>
                          <a href="https://twitter.com/PerfectWebServ1"><i className="fa-brands fa-x-twitter"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      <section className="why-choose padd">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading">
                  <div className="small-subheading">
                    <span>Why choose us</span>
                    <hr />
                  </div>
                  <h2 className="text-center">5 Reasons to partner  with Perfect Web Services</h2>
                </div>
            </div>
            <div className="col-12">
              <div className="reasons-blocks">
                <div className="reasons-block">
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UpperFooter/>
      <Footer />
    </main>
  );
};

export default AboutUs;
