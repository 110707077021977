import React from 'react'
import AboutOne from "../../../Images/AboutOne.webp"
import AboutTwo from "../../../Images/AboutTwo.webp"
import Check from "../../../Images/check.svg"
import aboutBg from "../../../Images/aboutbg.webp"
import arrowRight from "../../../Images/arrow.svg"


const Aboutus = () => {
    return (
        <section className='about-us padd' style={{ backgroundImage: `url(${aboutBg})`,  backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="about-image-block">
                            <div className="about-first-image">
                                <img src={AboutOne} alt="AboutOne" />
                            </div>
                            <div className="about-second-image">
                                <img src={AboutTwo} alt="AboutTwo" />
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="about-content-block">
                            <div className="section-heading">
                                <div className="small-subheading">
                                    <span>About Our Company</span>
                                    <hr></hr>
                                </div>
                                <h2>Exclusive Technology to Provide IT Solutions & Services</h2>
                            </div>
                            <div className="about-bottom-content">
                                <p>Web designing in a powerful way of just not an only professions, however, in a passion for our Company. We have to a tendency to believe the idea that smart looking of any website is the first impression on visitors.</p>
                                <ul>
                                    <li>
                                        <img src={Check} alt="check" /> Manage Tech Services 
                                    </li>
                                    <li>
                                        <img src={Check} alt="check" /> Problem Solving
                                    </li>
                                    <li>
                                        <img src={Check} alt="check" /> Innovative Approach 
                                    </li>
                                    <li>
                                        <img src={Check} alt="check" /> Innovative Approach 
                                    </li>
                                </ul>
                            </div>
                            <div className="about-button">
                                <a href="#contactus" className="button">Explore <img src={arrowRight} alt="arrow" /></a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Aboutus
