import React from 'react'
import SpecificationOne from "../../../Images/Specification-One.svg"
import SpecificationTwo from "../../../Images/Specification-Two.svg"
import SpecificationThree from "../../../Images/Specification-Three.svg"

const Specifications = () => {
    return (
        
        <section className="Sepcifications-block">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-12">
                        <div className="Sepcification-data">
                            <div className="specification-icon">
                                <img src={SpecificationOne} alt="Specification-One" />
                            </div>
                            <div className="specification-content">
                                <h5>Client-Centric Solutions</h5>
                                <p>Tailored services to meet unique business needs effectively.</p>
                            </div>  
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="Sepcification-data">
                            <div className="specification-icon">
                                <img src={SpecificationTwo} alt="Specification-Two" />
                            </div>
                            <div className="specification-content">
                                <h5>Scalable Development</h5>
                                <p>Tailored services to meet unique business needs effectively.</p>
                            </div>  
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="Sepcification-data">
                            <div className="specification-icon">
                                <img src={SpecificationThree} alt="Specification-Three" />
                            </div>
                            <div className="specification-content">
                                <h5>Quality Assurance</h5>
                                <p>Tailored services to meet unique business needs effectively.</p>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Specifications