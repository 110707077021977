import React from 'react'
import arrowRight from "../../../Images/arrow.svg"
import Connect from "../../../Images/connect.webp"


const Process = () => {
    return (
       
            <section className="ConnectUs padd">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="ConnectUs-data">
                                <div className="ConnectUs-bg">
                                    <div className='Connectleft'>
                                        <img src={Connect} alt="Connect" />
                                    </div>
                                    <div className='Connectright'>
                                        <div className="section-heading">
                                            <h2 className="">Stay Connected with Top-Notch Services</h2>
                                            <div className="connect-buttons">
                                                <a href="#contactus" className="button">Call Now <img src={arrowRight} alt="arrow" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </section>
    )
}

export default Process