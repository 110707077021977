import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slideImageone from "../../../Images/slide-image-one.webp";
import CampaignCreatorsimg from "../../../Images/campaign-creators-img.webp";
import bannerImgthird from "../../../Images/banner-img-03.webp";
import bannerImgfourth from "../../../Images/banner-img.webp";

import arrowRight from "../../../Images/arrow.svg";

const Banner = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const textArray = ["UI/UX Designing", "Web Designing", "Web Development", "Digital Marketing", "React Frontend", "MEAN/MERN"];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    }, 3000); // Change text every 3 seconds
    return () => clearInterval(interval);
  }, [textArray.length]);

  return (
    <section className="home-slider">
      <div className="home-slider-inner">
        <Slider {...settings} className="main-slider-one__carousel">
          <div className="item">
            <img src={slideImageone} alt="slide-image-one" />
          </div>
          <div className="item">
            <img src={CampaignCreatorsimg} alt="CampaignCreatorsimg" />
          </div>
          <div className="item">
            <img src={bannerImgthird} alt="bannerImgthird" />
          </div>
          <div className="item">
            <img src={ bannerImgfourth} alt=" bannerImgfourth" />
          </div>
         
          
        </Slider>
        <div className="banner-caption-block">
          <div className="container">
            <div className="carousel-main-caption">
              <h1 className="main-title">
                Excellent{" "}
                <div className="animation-text">
                  {textArray.map((text, index) => (
                    <span
                      key={index}
                      className={index === activeIndex ? "active" : "inactive"}
                    >
                      {text}
                    </span>
                  ))}
                </div>{" "}
                Services for Business Growth
              </h1>
              <p>
                Empowering your business with cutting-edge IT solutions tailored
                for growth. Experience seamless technology that drives success.
              </p>
              <div className="main-slider-one__link">
                <a href="#contactus" className="button">
                  Get Your Quote <img src={arrowRight} alt="arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

   
    </section>
  );
};

export default Banner;
