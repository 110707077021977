import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import loader from '../../Images/loader-c.gif'
const Allblog = () => {
    const [blogItems, setBlogItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6); 

    const handleDisplayBlog = async () => {
        try {
            const url = `https://perfectwebservices.com/Apis/showblog.php?timestamp=${Date.now()}`;
            const response = await axios.get(url);
            console.log(response.data.blogItems);
            if (response.data && response.data.blogItems) {
                setBlogItems(response.data.blogItems);
            } else {
                console.error("Error: Blog data not found in API response.");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleDisplayBlog();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString("en-US", options);
    };
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = blogItems.slice(indexOfFirstItem, indexOfLastItem);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const totalPages = Math.ceil(blogItems.length / itemsPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <>
        <Header/>
        <section className="our-portfolio" id="portfolio">
            <div className="container">
                <div className="row">
                    <div className="section-heading">
                        <div className="small-subheading">
                            <span>Our Blog</span>
                            <hr></hr>
                        </div>
                        <h2 className="text-center">Latest News and Insights</h2>
                    </div>
                    {isLoading ? (
                        <img className="loader-c" src={loader} />
                    ) : (
                        currentItems.map((item, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="course_card">
                                    <div className="course_card_img">
                                        <img
                                            src={`https://perfectwebservices.com/admin${item.image}`}
                                            alt={item.title}
                                        />
                                    </div>
                                    <div className="middle-section">
                                        <div className="course_card_content">
                                            <h5 className="title">{item.title}</h5>
                                            <p className="description">
                                                {item.description}
                                            </p>
                                        </div>
                                        <div className="course_card_footer">
                                            <p className="nav-item">{formatDate(item.created_at)}</p>
                                            <a
                                                className="nav-item read-more"
                                                href={`/blog/${item.slug}`}
                                            >
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}

                    {/* Pagination */}
                    <div className="d-flex justify-content-center mt-4 custom-pagination">
                        <nav aria-label="Page navigation custom-pagination">
                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                </li>
                                {pageNumbers.map((number) => (
                                    <li
                                        key={number}
                                        className={`page-item ${
                                            currentPage === number ? "active" : ""
                                        }`}
                                    >
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageChange(number)}
                                        >
                                            {number}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${
                                        currentPage === totalPages ? "disabled" : ""
                                    }`}
                                >
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    );
};

export default Allblog;
