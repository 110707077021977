import React, { useState, useEffect } from "react";
import axios from "axios";

const BlogDetailSidebar = () => {
    const [blogItems, setBlogItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleAddBlog = async () => {
        try {
            const url = `https://perfectwebservices.com/Apis/getcategory.php`;
            const response = await axios.get(url);
            console.log("my-response", response.data.blogItems);

            if (response.data && response.data.blogItems) {
                const sortedBlogs = response.data.blogItems.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
                setBlogItems(sortedBlogs.slice(0, 4));
            } else {
                console.error("Error: Blog data not found in API response.");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleAddBlog();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString("en-US", options);
    };

    const getFirstCategory = (categories) => {
        return categories ? categories.split(",")[0] : "Uncategorized";
    };

    return (
        <div className="article-sidebar-details">
            <div className="newsletter-article-details">
                <h3 className="heading-articale">Latest Blog</h3>
                <div className="latest-article-posts" id="dynamic">
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : blogItems.length > 0 ? (
                        blogItems.map((blog) => (
                            <div key={`${blog.id}-${blog.slug}`} className="article-posts-latests">
                                <a href={`/blog/${blog.slug}`}>
                                    <h4>{blog.title}</h4>
                                </a>
                                <p>{getFirstCategory(blog.category_names)} | {formatDate(blog.created_at)}</p>
                            </div>
                        ))
                    ) : (
                        <p>No blog posts available.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlogDetailSidebar;
