import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from '../Header/Header'
import Banner from '../Pages/HomeComponents/Banner'
import Specifications from "../Pages/HomeComponents/Specifications";
import Aboutus from "../Pages/HomeComponents/Aboutus";
import Process from "../Pages/HomeComponents/Process";
import Getyourquote from "../Pages/HomeComponents/Getyourquote";
import Testimonials from "../Pages/HomeComponents/Testimonials";
import Services from "../Pages/HomeComponents/Services";
import Portfolio from "../Pages/HomeComponents/PortfolioData";
import Stayconnect from "../Pages/HomeComponents/Stayconnect";
import Blog from "../Pages/HomeComponents/Blog";
import Footer from "../Footer/Footer";
import UpperFooter from '../Footer/UpperFooter'

const Homepage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.sectionId) {
      const section = document.getElementById(location.state.sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <main className="home-main">
      <Header/>
      <Banner />
      <Specifications />
      <Aboutus />
      <Services />
      <Process />
      <Getyourquote/>
      <Portfolio />
      <Testimonials/>
      <Blog/>
      <Stayconnect/>
      <UpperFooter/>
      <Footer/>
    </main>
  );
};

export default Homepage;
