// import './App.css';
import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import "../src/Perfectwebcss/App.css"
import "../src/Perfectwebcss/Responsive.css"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Marketing from './Components//Pages/real-estate-web-solutions-dubai.jsx';
import Privacypolicy from './Components//Pages/Privacypolicy.jsx';
import Seo from './Components/Pages/seo-for-plumbers'
import Homepage from './Components/Pages/Homepage';
import BlogDetails from "./Components/Pages/BlogDetails"
import NotFoundPage from './Components/Pages/NotFoundPage';
import Allblog from './Components/Pages/Allblog'
import Aboutus from './Components/Pages/AboutUs'

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Homepage />} />
        <Route path="/real-estate-web-solutions-dubai" element={<Marketing />} />
        <Route path="/seo-for-plumbers" element={<Seo />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/allblog" element={<Allblog />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/blog/:slug" element={<BlogDetails />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App


