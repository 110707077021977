import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import counterFirst from "../../../Images/counterfirst.svg";
import counterTwo from "../../../Images/countertwo.svg";
import counterThird from "../../../Images/counterthird.svg";
import counterForth from "../../../Images/counterforth.svg";

const CounterSection = () => {
  const { ref: counterRef, inView: counterInView } = useInView({
    triggerOnce: true, 
  });

  return (
    <div className="counterdata" ref={counterRef}>
      {counterInView && (
        <div className="counters">
          <div className="counter-item">
            <div className="counter-icon">
              <img src={counterFirst} alt="counterfirst" />
            </div>
            <div className="counter-content">
              <h3>
                <CountUp end={6561} duration={3} /> +
              </h3>
              <p>Satisfied Clients</p>
            </div>
          </div>
          <div className="counter-item">
            <div className="counter-icon">
              <img src={counterThird} alt="counterThird" />
            </div>
            <div className="counter-content">
              <h3>
                <CountUp end={600} duration={3} /> +
              </h3>
              <p>Finished Projects</p>
            </div>
          </div>
          <div className="counter-item">
            <div className="counter-icon">
              <img src={counterTwo} alt="counterTwo" />
            </div>
            <div className="counter-content">
              <h3>
                <CountUp end={250} duration={3} /> +
              </h3>
              <p>Skilled Experts</p>
            </div>
          </div>
          <div className="counter-item">
            <div className="counter-icon">
              <img src={counterForth} alt="counterForth" />
            </div>
            <div className="counter-content">
              <h3>
                <CountUp end={5900} duration={3} /> +
              </h3>
              <p>Media Posts</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CounterSection;
