import React, { useState, useEffect } from "react";
import axios from "axios";

const Blog = () => {
    const [blogItems, setBlogItems] = useState([]);
    const [stickyPost, setStickyPost] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 

    const handleAddBlog = async () => {
        try {
            const url = `https://perfectwebservices.com/Apis/showblog.php?timestamp=${Date.now()}`;
            const response = await axios.get(url);

            if (response.data && response.data.blogItems) {
                const blogs = response.data.blogItems;
                const sticky = blogs.find((item) => parseInt(item.sticky, 10) === 1);

                setStickyPost(sticky || null);
                setBlogItems(blogs.filter((item) => parseInt(item.sticky, 10) !== 1));
            } else {
                console.error("Error: Blog data not found in API response.");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false); 
        }
    };

    useEffect(() => {
        handleAddBlog();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString("en-US", options);
    };

    return (
        <section className="our-blog padd pb-0" >
            <div className="container">
                <div className="row">
                <div className="section-heading">
                    <div className="small-subheading">
                        <span>Our Blog</span>
                        <hr></hr>
                        </div>
                        <h2 className="text-center">Latest News and Insights</h2>
                        </div>
                    {/* Sticky Post Section */}
                    {isLoading ? (
                        <div className="loading-message col-md-12">
                            <p>Loading sticky post...</p>
                        </div>
                    ) : stickyPost ? (
                        <div className="sticky-post col-md-12">
                            <div className="course_card">
                                <div className="course_card_img stick-section-img">
                                    <img
                                        src={`https://perfectwebservices.com/admin${stickyPost.image}`}
                                        alt={stickyPost.title}
                                    />
                                </div>
                                <div className="middle-section sticky-section-content">
                                    <div className="course_card_content">
                                        <h3 className="title">{stickyPost.title}</h3>
                                        <p className="description">
                                            {stickyPost.description}
                                        </p>
                                    </div>
                                    <div className="course_card_footer">
                                        <p className="nav-item">{formatDate(stickyPost.created_at)}</p>
                                        <a className="nav-item read-more" href={`/blog/${stickyPost.slug}`}>
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="sticky-post col-md-12">
                            <p>No sticky post available.</p>
                        </div>
                    )}

                    {/* Regular Blog Items */}
                    {blogItems.slice(0, 3).map((item, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="course_card">
                                <div className="course_card_img">
                                    <img
                                        src={`https://perfectwebservices.com/admin${item.image}`}
                                        alt={item.title}
                                    />
                                </div>
                                <div className="middle-section">
                                    <div className="course_card_content">
                                        <h3 className="title">{item.title}</h3>
                                        <p className="description">
                                            {item.description}
                                        </p>
                                    </div>
                                    <div className="course_card_footer">
                                        <p className="nav-item">{formatDate(item.created_at)}</p>
                                        <a
                                            className="nav-item read-more"
                                            href={`/blog/${item.slug}`}
                                        >
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    );
};

export default Blog;
