import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import BlogSidebar from '../Pages/BlogDetailSidebar'
import DOMPurify from "dompurify"; 
const BlogDetails = () => {
const { slug } = useParams();
const [blog, setBlog] = useState(null);
const [isLoading, setIsLoading] = useState(true);
useEffect(() => {
const fetchBlogDetails = async () => {
try {
const url = `https://perfectwebservices.com/Apis/blogdetails.php`;
const response = await axios.post(url, { slug });
console.log("API Response:", response.data);
if (response.data && response.data.blogDetails) {
console.log("Blog Details:", response.data.blogDetails);
setBlog(response.data.blogDetails);
} else {
console.error("Error: Blog details not found in API response");
}
} catch (error) {
console.error("Error fetching blog details:", error);
} finally {
setIsLoading(false);
}
};
if (slug) {
fetchBlogDetails();
}
}, [slug]);
if (isLoading) {
return 
<p>Loading...</p>
;
}
if (!blog) {
return 
<p>No blog details found.</p>
;
}
return (
<>
<Header/>
<section className="blog-details">
   <div className="container">
      <div className="row">
         <div className="col-md-8">
            <div className="article-content-data">
            <div className="tp-blog">
               <img
                  src={`https://perfectwebservices.com/admin${blog.image}`}
                  alt={blog.title}
                  className="blog-image"
                  />
               <div className="title-blg">
                  <h2 className="text-center">{blog.title}</h2>
                  <p className="text-center">
                     {new Date(blog.created_at).toLocaleDateString("en-US", {
                     year: "numeric",
                     month: "long",
                     day: "numeric",
                     })}
                  </p>
               </div>
            </div>
            <div
            className="blog-content"
            dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(blog.blog_description),
            }}
            />
           </div>
         </div>
         <div className="col-md-4">
         <BlogSidebar/>
      </div>
      </div>
   </div>
</section>
<Footer/>
</>
);
};
export default BlogDetails;