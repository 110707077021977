import React from "react";
import Slider from "react-slick";
import servicesData from "../servicesdata";
import { useInView } from "react-intersection-observer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CounterSection from "./CounterSection";

const Services = () => {
    const counterData = [
        {
          icon: "../../../Images/counterfirst.svg",
          alt: "SatisfiedClients",
          value: 6561,
          label: "Satisfied Clients",
        },
        {
          icon: "../../../Images/counterthird.svg",
          alt: "FinishedProjects",
          value: 600,
          label: "Finished Projects",
        },
        {
          icon: "../../../Images/countertwo.svg",
          alt: "SkilledExperts",
          value: 250,
          label: "Skilled Experts",
        },
        {
          icon: "../../../Images/counterforth.svg",
          alt: "MediaPosts",
          value: 5900,
          label: "Media Posts",
        },
      ];
    
    const { ref: counterRef, inView: counterInView } = useInView({
    triggerOnce: true, 
    });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="ourservices padd" id="ourservices">
      <div className="container">
        <div className="section-heading">
            <div className="small-subheading">
                <span>Services we’re offering</span>
                <hr></hr>
            </div>
            <h2 className="text-center">We Offer a Wide Variety of IT Services</h2>
        </div>
        <Slider {...settings}>
          {servicesData.map((service) => (
            <div key={service.id} className="service-card">
                <a href="/"> 
                    <div className="overlay-blue">
                        <img className="left-blue lb" src={service.iconSrc2} alt={service.title}/>
                        <img className="right-blue rb" src={service.iconSrc3} alt={service.title}/>
                    </div>
                    <div className="our-services-box">
                        <div className="services-img">
                            <img src={service.imgSrc} alt={service.title}/> 
                        </div>
                        <div className="services-box-data">
                            <div className="services-icon-contain">
                                <img src={service.iconSrc} alt={`${service.title} icon`} />
                            </div>
                            <h5>{service.title}</h5>
                            <p>{service.description}</p>
                        </div>
                    </div>
                </a>
            </div>
          ))}
        </Slider>
              {/* Counter Section */}
              <div ref={counterRef}>
              {counterInView && <CounterSection counters={counterData} />}
            </div>
      </div>
    </section>
  );
};

export default Services;
