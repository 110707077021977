import React from "react";
import whiteWebsiteLogo from "../../Images/white-website-logo.svg";

const Footer = () => {


  return (
    <section className="footer-container-sec" id="contactus">
      <footer className='footer-sec'>
                <div className="footer-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="subscribe-block">
                                    <div className="susbcribe-newsletter">
                                        <h5>Subscribe to our newsletter!</h5>
                                        <form  action="">
                                            <div className='form-group'>
                                                <div className="input-group">
                                                    {/* <input ref={emailAddressRef} onChange={(e) => { setEmailAddress(e.target.value); }} required name="emailAddress" type="email" placeholder='Email' /> */}
                                                    <input required name="emailAddress" type="email" placeholder='Email' />

                                                </div>
                                                <div className="subscribe-button">
                                                    <button type="button">Subscribe</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-3 col-12">
                                <div className="footer-column-sec">
                                    <div className="footer-logo">
                                        <img src={whiteWebsiteLogo} alt="brandLogo" />
                                    </div>
                                    <p> We're a team of passionate developers, designers, and marketers who are dedicated to helping businesses transform their online presence. </p>
                                    <div className="social-icons">
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/perfectwebservices"><i className="fa-brands fa-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/company/perfect-web-services/"><i className="fa-brands fa-linkedin"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/perfect_web_services/"><i className="fab fa-instagram"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/PerfectWebServ1"><i className="fa-brands fa-x-twitter"></i></a>
                                            </li>
                                        </ul>
                                    </div>                              
                                </div>
                            </div>
                            <div className="col-md-3 col-12">
                                <div className="footer-column-sec">
                                    <h5>Quick Links</h5>
                                    <ul className="footer-links">
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" href="/aboutus">About Us</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#ourservices">Our Services</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#portfolio">
                                                Portfolio
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#contactus" >
                                                Contact Us
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/allblog" >
                                                Blogs
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-12">
                                <div className="footer-column-sec">
                                    <h5>Our Services</h5>
                                    <ul className="footer-links">
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" href="/">UI/UX Designing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/">Web Designing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/">Web Development</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/" >Digital Marketing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/" >React Frontend</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/" >MEAN/MERN</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-12">
                                <div className="footer-column-sec">
                                    <h5>Contact Info</h5>
                                    <ul className="footer-info">
                                        <li>
                                            <i className="fa-solid fa-phone"></i>
                                            <p>
                                                <a href="tel:+917508738945">+917508738945</a>   
                                                <a href="tel:+918264958742">+918264958742</a>
                                              </p>
                                        </li>
                                        <li>
                                            <i className="fa-regular fa-envelope"></i>
                                            <p>
                                                <a href= "mailto:contact@perfectwebservices.com">contact@perfectwebservices.com</a>
                                            </p>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-location-dot"></i>
                                            <p>E-237AA, Phase 8B, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
                                        </li>
                                    </ul>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="copright">
                                    <p>© 2018-2024 Perfect Web Services. All right reserved</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="privacypolicy">
                                    <a href="/privacy-policy">Privacy Policy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    </section>
  );
};

export default Footer;
