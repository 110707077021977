import React, { useEffect } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import WebsiteLogo from "../../Images/logo.svg";
import PhoneIcon from "../../Images/phone-icon.svg";
import messageIcon from "../../Images/message-icon.svg";
import Menuicon from "../../Images/Menu.svg";
import MenuClose from "../../Images/close.svg";

const Header = () => {
  useEffect(() => {
    const header = document.querySelector('.bottom-header');
    const handleScroll = () => {
      if (window.scrollY > header.offsetTop) {
        header.classList.add('sticky-header');
      } else {
        header.classList.remove('sticky-header');
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    }, []);
    const toggleDropdown = (event) => {
      event.stopPropagation(); 
      const dropdownMenu = event.target.nextElementSibling; 
      dropdownMenu.classList.toggle('active'); 
    };

  return (
    <div>
        <HelmetProvider>
          <Helmet>
          <title>Best Web Development Services | Perfect Web Services</title>
          <meta name="title" content="Best Web Development Services | Perfect Web Services" />
          <meta name="description" content="Perfect Web Services provides the Best Web Development Services for your business across the world with advanced technologies & Effective Marketing Strategies." />
          <meta name="keywords" content="Effective Marketing Strategies, Advanced IT Solutions, Web Development Services, leading web solution company, creative website design services" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Best Web Development Services | Perfect Web Services" />
          <meta property="og:description" content="Perfect Web Services provides the Best Web Development Services for your business across the world with advanced technologies & Effective Marketing Strategies." />
          <meta property="og:image" content="https://perfectwebservices.com/sitelogo.png" />
          <meta property="og:url" content="https://perfectwebservices.com" />
          <meta property="og:locale" content="en_IN" />
          <meta property="og:site_name" content="Perfect Web Services" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:title" content="Best Web Development Services | Perfect Web Services" />
          <meta property="twitter:description" content="Perfect Web Services provides the Best Web Development Services for your business across the world with advanced technologies & Effective Marketing Strategies." />
          <meta property="twitter:image" content="https://perfectwebservices.com/sitelogo.png" />
          <meta property="twitter:url" content="https://perfectwebservices.com" />
          <link rel="canonical" href="https://perfectwebservices.com" />
        </Helmet>
      </HelmetProvider>
      <header>
        <div className="top-header">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-12 d-flex align-items-center">
                <a className="navbar-brand" href="/">
                  <img src={WebsiteLogo} alt="perfect-web-logo" />
                </a>
              </div>
              <div className="col-md-9 col-12">
                <div className="inquiry-flex">
                  <div className="inquiry-block">
                    <div className="inquiry-icon">
                      <img src={PhoneIcon} alt="phone-icon" />
                    </div>
                    <div className="inquiry-content">
                      <span>For inquiries Call us at:</span>
                      <p>
                        <a href="tel:+917508738945">+917508738945</a>
                      </p>
                    </div>
                  </div>
                  <div className="inquiry-block">
                    <div className="inquiry-icon">
                      <img src={messageIcon} alt="message-icon" />
                    </div>
                    <div className="inquiry-content">
                      <span>For inquiries reach us at:</span>
                      <p>
                        <a href="mailto:contact@perfectwebservices.com">contact@perfectwebservices.com</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-header">
          <div className="container">
            <div className="row">
              <div className="col-3 d-flex align-items-center">
                <div className="social-icons">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/perfectwebservices"><i className="fa-brands fa-facebook"></i></a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/perfect-web-services/"><i className="fa-brands fa-linkedin"></i></a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/perfect_web_services/"><i className="fab fa-instagram"></i></a>
                    </li>
                    <li>
                      <a href="https://twitter.com/PerfectWebServ1"><i className="fa-brands fa-x-twitter"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-9">
                <nav className="mobile-offcanvas navbar navbar-expand-lg d-none d-lg-block">
                  <div className="offcanvas-header">
                    <button className="btn-close float-end"> <img src={MenuClose} alt="MenuClose" /> </button>
                  </div>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="/">Home</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="/aboutus">About Us</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link dropdown-toggle" href="#" onClick={toggleDropdown}>Our Services</a>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href="/">UI/UX Designing</a></li>
                          <li><a className="dropdown-item" href="/">Web Designing</a></li>
                          <li><a className="dropdown-item" href="/">Web Development</a></li>
                          <li><a className="dropdown-item" href="/">Digital Marketing</a></li>
                          <li><a className="dropdown-item" href="/">React Frontend</a></li>
                          <li><a className="dropdown-item" href="/">MEAN/MERN</a></li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/">Portfolio</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </nav>
                <button data-trigger="navbar_main" className="d-lg-none mobile-menu-btn" type="button">
                  <img src={Menuicon} alt="menuicon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Navigation */}
      <nav className="mobile-offcanvas navbar navbar-expand-lg d-lg-none d-block">
        <div className="navbar-collapse" id="navbarSupportedContent">
          <div className="offcanvas-header">
            <div className="mobile-offcanvas-logo">
              <img src={WebsiteLogo} alt="perfect-web-logo" />
            </div>
            <button className="btn-close float-end"> <img src={MenuClose} alt="MenuClose" /> </button>
          </div>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/aboutus">About Us</a>
            </li>
            <li className="nav-item">
              <a className="nav-link dropdown-toggle" href="#" onClick={toggleDropdown}>Our Services</a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/">UI/UX Designing</a></li>
                <li><a className="dropdown-item" href="/">Web Designing</a></li>
                <li><a className="dropdown-item" href="/">Web Development</a></li>
                <li><a className="dropdown-item" href="/">Digital Marketing</a></li>
                <li><a className="dropdown-item" href="/">React Frontend</a></li>
                <li><a className="dropdown-item" href="/">MEAN/MERN</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">Portfolio</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">Contact Us</a>
            </li>
          </ul>
          <div className="sidebarmenu-footer">
            <h5>Contact Info:</h5>
            <ul className="footer-info">
              <li>
                <i className="fa-solid fa-phone"></i>
                <p><a href="tel:+9876543210">+9876543210</a>,<a href="tel:+918264958742">+918264958742</a></p>
              </li>
              <li>
                <i className="fa-regular fa-envelope"></i>
                <p><a href="mailto:contact@perfectwebservices.com">contact@perfectwebservices.com</a></p>
              </li>
              <li>
                <i className="fa-solid fa-location-dot"></i><p>E-237AA, Phase 8B, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
              </li>
            </ul>
            <div className="social-icons">
              <h5 className='mt-2 mb-3'>Social Media:</h5>
              <ul>
              <li>
                  <a href="https://www.facebook.com/perfectwebservices"><i className="fa-brands fa-facebook"></i></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/perfect-web-services/"><i className="fa-brands fa-linkedin"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/perfect_web_services/"><i className="fab fa-instagram"></i></a>
                </li>
                <li>
                  <a href="https://twitter.com/PerfectWebServ1"><i className="fa-brands fa-x-twitter"></i></a>
                </li>
              </ul> 
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
