import React from 'react';
import ProcessIconone from "../../../Images/Process-icon-one.svg";
import ProcessIcontwo from "../../../Images/Process-icon-two.svg";
import ProcessIconthree from "../../../Images/Process-icon-three.svg";
import ProcessIconfour from "../../../Images/Process-icon-four.svg";
import aboutBg from "../../../Images/aboutbg.webp";

const Process = () => {
    return (
        <section
            className="process-block padd"
            style={{
                backgroundImage: `url(${aboutBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="container">
                <div className="section-heading">
                    <div className="small-subheading">
                        <span>Work Process</span>
                        <hr></hr>
                    </div>
                    <h2 className="text-center">Our Software Development Process</h2>
                </div>
                <div className="row">
                    {[
                        { icon: ProcessIconone, title: "Requirement", text: "Requirements are the features, functions.", number: "01" },
                        { icon: ProcessIcontwo, title: "UI/UX Design", text: "Focuses on the look and feel of the product", number: "02" },
                        { icon: ProcessIconthree, title: "Prototype", text: "A prototype is an early sample or model of", number: "03" },
                        { icon: ProcessIconfour, title: "Development", text: "Development is the process of building the actual", number: "04" },
                    ].map((item, index) => (
                        <div className="col-md-3 col-12" key={index}>
                            <div className="process-data">
                                <div className="process-icon">
                                    <span>
                                        <img src={item.icon} alt={`ProcessIcon-${item.number}`} />
                                    </span>
                                </div>
                                <div className="process-content">
                                    <h5>{item.title}</h5>
                                    <p>{item.text}</p>
                                    <h3>{item.number}</h3>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Process;
