import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import contactImage from "../../Images/contactImage.webp";

const UpperFooter = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [websiteUrl, setWebsiteURL] = useState("");
  const [filterType, setFilterType] = useState("");
  const [tellusaboutyourproject, setTellusaboutyourproject] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailAddressRef = useRef(null);
  const websiteUrlRef = useRef(null);
  const tellusaboutyourprojectRef = useRef(null);
  const filterTypeRef = useRef(null);

  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !emailAddress || !filterType) {
      toast.error("All fields are required");
      return;
    }

    if (!isValidEmail(emailAddress)) {
      toast.error("Invalid email address");
      return;
    }

    setIsSubmitting(true);

    const data = {
      firstName,
      lastName,
      emailAddress,
      websiteUrl,
      filterType,
      tellusaboutyourproject,
    };

    axios
      .post("https://perfectwebservices.com/Apis/contactapi.php", data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res.data.success) {
          toast.success("Form submitted successfully");
          firstNameRef.current.value = "";
          lastNameRef.current.value = "";
          emailAddressRef.current.value = "";
          websiteUrlRef.current.value = "";
          tellusaboutyourprojectRef.current.value = "";
          filterTypeRef.current.value = "";
        } else {
          toast.error("Form submission failed");
        }
      })
      .catch(() => {
        setIsSubmitting(false);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setWebsiteURL(window.location.href);
      document.getElementById("websiteUrl").value = window.location.href;
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <section className="footer-container-sec" id="contactus">
      <div className="contactus-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-section">
                <div className="section-heading">
                  <h2>Get in touch</h2>
                  <p>We are here for you! How can we help?</p>
                </div>
                <form onSubmit={handleFormSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        ref={firstNameRef}
                        required
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        ref={lastNameRef}
                        required
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <input
                        ref={websiteUrlRef}
                        name="websiteUrl"
                        type="hidden"
                        id="websiteUrl"
                        onLoad={() =>
                          (document.getElementById("websiteUrl").value =
                            window.location.href)
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        ref={emailAddressRef}
                        required
                        name="emailAddress"
                        type="email"
                        placeholder="Email Address"
                        onChange={(e) => setEmailAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <select
                        ref={filterTypeRef}
                        required
                        name="filterType"
                        onChange={(e) => setFilterType(e.target.value)}
                      >
                        <option value="">Select Service</option>
                        <option value="uiuxdesign">Ui/Ux Design</option>
                        <option value="web_designing">Web Designing</option>
                        <option value="web_development">Development</option>
                        <option value="digital_marketing">
                          Digital Marketing
                        </option>
                        <option value="react_frontend">React Frontend</option>
                        <option value="mern_mean">MERN/MEAN</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <textarea
                        ref={tellusaboutyourprojectRef}
                        name="tellusaboutyourproject"
                        cols="2"
                        rows="3"
                        placeholder="Tell us about your project"
                        onChange={(e) =>
                          setTellusaboutyourproject(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <input
                    type="submit"
                    value={isSubmitting ? "Sending..." : "Send Message"}
                    disabled={isSubmitting}
                  />
                </form>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="contact-image">
                <img src={contactImage} alt="Contact" />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <ToastContainer />
    </section>
  );
};

export default UpperFooter;
